<template>
  <div class="unsubscribe-pages my-5">
    <div class="container">
      <div v-if="loading" class="text-center">
        <b-spinner></b-spinner>
      </div>
      <div v-else class="unsubscribe-email text-center">
        <h2>Subscription Management</h2>
        <div v-if="!success">
          <p>Are you sure you want to unsubscribe from our service? Please click button below to unsubscribe.</p>
          <div class="row mt-5">
            <div class="col-md-12">
              <p>Please take a moment and let us know why you unsubscribed.</p>
              <textarea v-model="content" rows="5" class="form-control"></textarea>
            </div>
            <div class="col-md-12 mt-4">
              <b-button variant="primary" :disabled="processing" type="button" @click="onUnsubscribe">
                <b-spinner v-if="processing" small />
                <span v-else>Unsubscribe</span>
              </b-button>
            </div>
          </div>
        </div>
        <div v-else>
          <p>You have successfully been unsubscribed from our service.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnsubscribeEmail',
  components: {
  },
  data() {
    return {
      loading: false,
      processing: false,
      success: false,
      content: '',
      token: null,
      id: null,
    }
  },

  mounted() {
    this.token = this.$route.params.token
    this.id = this.$route.params.id
    if (this.token) {
      this.verifyToken()
    } else {
      this.onError();
    }
  },

  methods: {
    onError() {
      // this.$router.push({ name: 'login' })
    },

    verifyToken() {
      this.loading = true
      const data = {
        token: this.token,
        access_type: 1
      }
      this.$store.dispatch('auth/verifyToken', data)
        .then(({ data, status }) => {
          this.loading = false
          if (status) {
            this.success = true
          }
        })
        .catch(() => {
          this.onError();
        })
    },

    onUnsubscribe() {
      this.processing = true
      const data = {
        token: this.token,
        id: this.id,
        access_type: 1,
        content: this.content,
      }
      this.$store.dispatch('auth/unsubscribe', data)
        .then(() => {
          this.processing = false
          this.success = true;
        })
        .catch(() => {
          this.processing = false
        })
    }
  },
}
</script>

<style lang="scss">
.unsubscribe-email {}
</style>
